import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Household = () => import('@/entities/household/household.vue');
// prettier-ignore
const HouseholdUpdate = () => import('@/entities/household/household-update.vue');
// prettier-ignore
const HouseholdDetails = () => import('@/entities/household/household-details.vue');
// prettier-ignore
const HouseholdMember = () => import('@/entities/household-member/household-member.vue');
// prettier-ignore
const HouseholdMemberUpdate = () => import('@/entities/household-member/household-member-update.vue');
// prettier-ignore
const HouseholdMemberDetails = () => import('@/entities/household-member/household-member-details.vue');
// prettier-ignore
const Questionnaire = () => import('@/entities/questionnaire/questionnaire.vue');
// prettier-ignore
const QuestionnaireUpdate = () => import('@/entities/questionnaire/questionnaire-update.vue');
// prettier-ignore
const QuestionnaireDetails = () => import('@/entities/questionnaire/questionnaire-details.vue');
// prettier-ignore
const QuestionnaireItem = () => import('@/entities/questionnaire-item/questionnaire-item.vue');
// prettier-ignore
const QuestionnaireItemUpdate = () => import('@/entities/questionnaire-item/questionnaire-item-update.vue');
// prettier-ignore
const QuestionnaireItemDetails = () => import('@/entities/questionnaire-item/questionnaire-item-details.vue');
// prettier-ignore
const Item = () => import('@/entities/item/item.vue');
// prettier-ignore
const ItemUpdate = () => import('@/entities/item/item-update.vue');
// prettier-ignore
const ItemDetails = () => import('@/entities/item/item-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'household',
      name: 'Household',
      component: Household,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household/new',
      name: 'HouseholdCreate',
      component: HouseholdUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household/:householdId/edit',
      name: 'HouseholdEdit',
      component: HouseholdUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household/:householdId/view',
      name: 'HouseholdView',
      component: HouseholdDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household-member',
      name: 'HouseholdMember',
      component: HouseholdMember,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household-member/new',
      name: 'HouseholdMemberCreate',
      component: HouseholdMemberUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household-member/:householdMemberId/edit',
      name: 'HouseholdMemberEdit',
      component: HouseholdMemberUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'household-member/:householdMemberId/view',
      name: 'HouseholdMemberView',
      component: HouseholdMemberDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'questionnaire',
      name: 'Questionnaire',
      component: Questionnaire,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'questionnaire/new',
      name: 'QuestionnaireCreate',
      component: QuestionnaireUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'questionnaire/:questionnaireId/edit',
      name: 'QuestionnaireEdit',
      component: QuestionnaireUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'questionnaire/:questionnaireId/view',
      name: 'QuestionnaireView',
      component: QuestionnaireDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'questionnaire-item',
      name: 'QuestionnaireItem',
      component: QuestionnaireItem,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'questionnaire-item/new',
      name: 'QuestionnaireItemCreate',
      component: QuestionnaireItemUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'questionnaire-item/:questionnaireItemId/edit',
      name: 'QuestionnaireItemEdit',
      component: QuestionnaireItemUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'questionnaire-item/:questionnaireItemId/view',
      name: 'QuestionnaireItemView',
      component: QuestionnaireItemDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'item',
      name: 'Item',
      component: Item,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'item/new',
      name: 'ItemCreate',
      component: ItemUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'item/:itemId/edit',
      name: 'ItemEdit',
      component: ItemUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'item/:itemId/view',
      name: 'ItemView',
      component: ItemDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
