import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
// prettier-ignore
const Explore = () => import('@/pages/explore/explore.vue');

const Design = () => import('@/pages/design/design.vue');

const Sample = () => import('@/pages/sample/sample.vue');

const Survey = () => import('@/pages/survey/survey.vue');

const Tutorial = () => import('@/pages/tutorial/tutorial.vue');

const ExploreTown = () => import('@/pages/explore/exploretown.vue');

const SampleTown = () => import('@/pages/sample/sampletown.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/pages/explore',
    name: 'Explore',
    component: Explore,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/pages/design',
    name: 'Design',
    component: Design,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/pages/sample',
    name: 'Sample',
    component: Sample,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/pages/survey',
    name: 'Survey',
    component: Survey,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/pages/tutorial',
    name: 'Tutorial',
    component: Tutorial,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/pages/explore/:townId/:sample/town',
    name: 'ExploreTown',
    component: ExploreTown,
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/pages/sample/:townId/:sample/town',
    name: 'SampleTown',
    component: SampleTown,
    meta: { authorities: [Authority.USER] },
  },
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
];
