import axios, { AxiosPromise } from 'axios';

const baseApiUrl = 'https://surveylab.sun.ac.za';

export default class SurveyService {
  public getAdminTime(qnum, mnum, hsample, isample): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        //.get(baseApiUrl + '/scost?qnum=' + qnum + '&mnum=' + mnum + '&hsample=' + hsample + '&isample=' + isample)
        .post(baseApiUrl + '/scost', {
          qnum: qnum,
          mnum: mnum,
          hsample: hsample,
          isample: isample,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public collectData(isample, stowns, quest): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        //.get(baseApiUrl + '/sdata?isample=' + isample + '&stowns=' + stowns + '&quest=' + quest)
        .post(baseApiUrl + '/sdata', {
          isample: isample,
          stowns: stowns,
          quest: quest,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
