// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from './admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
/* tslint:disable */

import DesignService from '@/pages/design/design.service';
import TutorialService from '@/pages/tutorial/tutorial.service';
import ExploreService from '@/pages/explore/explore.service';
import ExploreTownService from '@/pages/explore/exploretown.service';

import SampleService from '@/pages/sample/sample.service';
import SurveyService from '@/pages/survey/survey.service';
import panZoom from 'vue-panzoom';

import HouseholdService from '@/entities/household/household.service';
import HouseholdMemberService from '@/entities/household-member/household-member.service';
import ItemService from '@/entities/item/item.service';
import QuestionnaireService from '@/entities/questionnaire/questionnaire.service';
import QuestionnaireItemService from '@/entities/questionnaire-item/questionnaire-item.service';

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
// install plugin
Vue.use(panZoom);

const store = config.initVueXStore(Vue);

const loginService = new LoginService();
const accountService = new AccountService(store, router);

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userManagementService: () => new UserManagementService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    designService: () => new DesignService(),
    tutorialService: () => new TutorialService(),
    exploreService: () => new ExploreService(),
    exploreTownService: () => new ExploreTownService(),
    householdService: () => new HouseholdService(),
    householdMemberService: () => new HouseholdMemberService(),
    questionnaireService: () => new QuestionnaireService(),
    questionnaireItemService: () => new QuestionnaireItemService(),
    itemService: () => new ItemService(),
    sampleService: () => new SampleService(),
    surveyService: () => new SurveyService(),
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
  },
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        loginService.openLogin(vue);
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
